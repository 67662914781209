<template>
  <div class="money-con">
    <NavBar title="支付金额" @click-left="goBack" :left-arrow="isWeixin_status == false"></NavBar>
    <div class="set-money">
      <label for="">加油金额</label>
      <div class="set-money-top">
        <input type="text" disabled :value="money" placeholder="请输入消费金额">
        <span>约L</span>
      </div>
    </div>
    <div class="set-con">
      <div class="set-con-item">
        <span>直降</span>
        <span class="money-icon">0.00</span>
      </div>
      <div class="set-con-item">
        <span>优惠券</span>
        <span>暂无可用优惠券</span>
      </div>
    </div>
    <Coupions></Coupions>
    <KeyNumber @goMoney="setPayMoney"></KeyNumber>
  </div>
</template>
<script>
import { NavBar } from "vant";
import KeyNumber from "@components/public/KeyNumber";
import Coupions from "./coupions";
export default {
  components: {
    KeyNumber, NavBar, Coupions
  },
  data() {
    return {
      money: ""
    }
  },
  methods: {
    setPayMoney(val) {
      console.log(val + 'aaaaaa')
      this.money = val;
    }
  }
}
</script>
<style>
input[disabled]{
  background-color: #ffffff;
  color:#ACA899;
}
</style>
<style lang="scss" scoped>
.money-con {
  width: 100%;
  height: 100vh;
  background: #ffffff;
  overflow: hidden;
  .set-money {
    width: 96%;
    height: 70px;
    display: flex;
    padding: 0 16px;
    font-size: 14px;
    margin: 10px auto;
    align-items: center;
    border-radius: 6px;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    justify-content: space-between;
    label {
      width: 80px;
      padding-left: 8px;
      color: #363636;
      font-size: 14px;
      border-left: 2px solid red;
    }
    .set-money-top {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      input {
        width: 140px;
        border: none;
        font-size: 16px;
        color: red;
      }
    }
  }
  .set-con {
    width: 96%;
    padding: 0 16px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    .set-con-item {
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: space-between;
      span:nth-child(1) {
        font-size: 14px;
        color: #363636;
      }
      .money-icon {
        font-size: 18px;
        color: #000;
      }
      .money-icon::before {
        position: relative;
        font-size: 10px;
        content: "\A5";
      }
    }
  }
}
</style>