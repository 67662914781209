<template>
  <div>
    <!--优惠券-->
    <Popup v-model="showCop" round position="bottom" style="height: 60%;">
      <div class="ticket-tab">
        <div
          :class="[now_tabIdx == idx ? 'active' : '']"
          v-for="(item, idx) in ticketType"
          :key="idx"
          :title="item"
          @click="now_tabIdx = idx"
        >
          {{ item }}
        </div>
      </div>
      <p class="hint youhui_hint">温馨提示: 以上三种优惠券不能同时使用</p>
      <div class="ticket-list">
        <RadioGroup v-model="now_ticketId">
          <div
            class="ticket-item"
            v-for="item in myCoupons"
            :key="item.id"
          >
            <!-- 如果互斥执行selectDiscounts('discounts') -->
            <div class="ticket-item-top">
              <div class="ticket-item-top-money">
                <!--满减券-->
                <h2 v-if="item.coupon_data.type == 1">
                  {{ item.coupon_data.m_dec_money }}
                  <span>元</span>
                </h2>
                <!--折扣券-->
                <h2 v-if="item.coupon_data.type == 2">
                  {{ item.coupon_data.zhe_discount }}
                  <span>折</span>
                </h2>
                <!--代金券-->
                <h2 v-if="item.coupon_data.type == 3">
                  {{ item.coupon_data.d_face_value }}
                  <span>元</span>
                </h2>
                <p>{{ item.coupon_data.sub_title }}</p>
              </div>
              <div class="ticket-item-top-name">
                <p>{{ item.coupon_data.title }}</p>
                <p>{{ item.exp_time }}</p>
                <div class="right-btn">
                  <Radio :name="item.id"></Radio>
                </div>
              </div>
            </div>
            <div class="ticket-item-bottom">描述信息</div>
          </div>
        </RadioGroup>
      </div>
      <div class="nonuse-coupon">
        <Button type="danger" size="small" block>不使用优惠券</Button>
      </div>
    </Popup>
</div>
</template>
<script>
import CreditShop from "@/api/creditshop";
import { RadioGroup, Popup } from "vant";
export default {
  components: {
    RadioGroup, Popup
  },
  data() {
    return {
      ticketType: ["满减券", "折扣券", "代金券"],
      myCoupons: [],
      now_tabIdx: 0,
      showCop: true,
      now_ticketId: ""
    }
  },
  onload() {
    this.getCoupons()
  },
  methods: {
    // 获取优惠券
		async getCoupons() {
			try {
				this.myCoupons = [];
				const res = await CreditShop.getAvailable(
					400,
					this.now_tabIdx + 1,
					2,
					22,
					2
				);
				if (res.code == 200) {
					// 可使用优惠券数量
					if (res.data.length > 0) {
						this.myCoupons = res.data;
					}
				} 
				this.is_used_num = res.num;
			} catch (error) {
				console.log(error);
			}
		}
  }
}
</script>
<style lang="scss" scoped>
.ticket-tab {
  width: 100%;
  position: fixed;
  display: flex;
  user-select: none;
  background-color: #fff;
  border-bottom: 1px solid #dfdfdf;
  border-radius: 20px 20px 0 0;
  z-index: 200;
  div,
  .active {
    position: relative;
    flex: 1;
    box-sizing: border-box;
    min-width: 0;
    padding: 0 5px;
    color: #646566;
    font-size: 14px;
    line-height: 44px;
    text-align: center;
    cursor: pointer;
  }

  .active {
    color: #ee0a24;
  }
}

.youhui_hint {
  margin-top: 45px;
  text-align: center;
}

.nonuse-coupon {
  height: 40px;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 30px;
}

.ticket-list {
  width: 100%;
  height: 320px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding-top: 5px;

  .ticket-item {
    width: 96%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    display: flex;
    overflow: hidden;
    margin-top: 10px;
    padding-top: 14px;
    flex-direction: column;
    justify-content: space-between;

    .ticket-item-top {
      width: 100%;
      min-height: 84px;
      border-bottom: 1px dashed #ebedf0;
      display: flex;
      padding: 6px 10px;
      justify-content: space-between;

      .ticket-item-top-money {
        position: relative;
        min-width: 96px;
        padding: 0 8px;
        color: #ee0a24;
        text-align: center;
        color: #ee0a24;

        h2 {
          margin-bottom: 6px;
          font-weight: 500;
          font-size: 16px;
          overflow: hidden;

          span {
            font-weight: normal;
            font-size: 60%;
          }
        }

        p {
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 12px;
          line-height: 16px;
          white-space: pre-wrap;
        }
      }

      .ticket-item-top-name {
        flex: 1;
        min-width: 220px;
        height: 100%;
        position: relative;

        p:nth-child(1) {
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
        }

        p {
          margin-bottom: 10px;
        }

        .right-btn {
          position: absolute;
          top: 0;
          right: 16px;
          bottom: 0;
          display: flex;
          align-items: center;
        }
      }
    }

    .ticket-item-bottom {
      padding: 6px;
    }
  }

  .bottom-text {
    width: 100%;
    height: 25px;
    position: fixed;
    left: 0;
    bottom: 0;
    line-height: 25px;
    text-align: center;
    font-size: 14px;
    color: #000000;
    background: #ecf0f1;
  }
}
</style>